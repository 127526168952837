export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "View our best curated works",
  headline: "Featured Works",
  description:
    "Aukiss TV is an entertainment lifestyle company that taps entertainment industry talent to influence and educate the masses via trends, exclusive content and technological integration. Welcome to a new world, where we look forward to entertaining and providing you with content and products that cater to your specific tastes and needs.",
  buttonLabel: "View Work",
  imgStart: "start",
  img: "images/atv.png",
  alt: "Vault",
  url: "//aukiss.tv",
  target: "_blank",
};

export const homeObjFive = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Afrotales+ app",
  headline: "Featured Works",
  description:
    "Afrotales+ is a video demand movie streaming application developed by coderichsoftwares. This is an initiative to support Ghanaian and African startup companies at a small fee. Afrotales+ offers authentic African content to its subscribers.Its serves as your one stop shop for African entertaining content, from animate movies to animaed series,comics and games.",
  buttonLabel: "View Work",
  imgStart: "start",
  img: "images/afr.png",
  alt: "Vault",
  url: "//play.google.com/store/apps/details?id=com.millsmedia.afrotalesplus&hl=en",
  target: "_blank",
};

export const homeObjSix = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "AECINEMAX",
  headline: "Featured Works",
  description:
  "Your African Cinema Experience",
  buttonLabel: "View Work",
  imgStart: "start",
  img: "images/aec.png",
  alt: "Aecinemax",
  url: "//aecinemax.com",
  target: "_blank",
};

