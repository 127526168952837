export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "We are CodeRich",
  headline: "A company focused on training and building softwares",
  description: "",
  buttonLabel: "View Our Works",
  imgStart: "",
  img: "images/main2.jpeg",
  alt: "Credit Card",
  url: "/featured_works",
  target: "_self",
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "CodeRich is a EdTech/Software Company",
  headline: "Coding Class in Scratch for The Winkles School.",
  description:
    "Aimed at impacting society by training younger people and also developing quality software products that help companies reach their full potential and attract more customers.",
  buttonLabel: "Learn More",
  imgStart: "",
  img: "images/learning.jpg",
  alt: "Vault",
  url: "//web.facebook.com/thewinklesschool/photos/pcb.2595281010705446/2595272704039610/",
  target: "_blank",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "View our best curated works",
  headline: "Featured Works",
  description:
    "Aukiss TV is an entertainment lifestyle company that taps entertainment industry talent to influence and educate the masses via trends, exclusive content and technological integration. Welcome to a new world, where we look forward to entertaining and providing you with content and products that cater to your specific tastes and needs.",
  buttonLabel: "View Work",
  imgStart: "start",
  img: "images/atv.png",
  alt: "Vault",
  url: "//aukiss.tv",
  target: "_blank",
};

export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Afrotales+ app",
  headline: "Featured Works",
  description:
    "Afrotales+ is a video demand movie streaming application developed by coderichsoftwares. This is an initiative to support Ghanaian and African startup companies at a small fee. Afrotales+ offers authentic African content to its subscribers.Its serves as your one stop shop for African entertaining content, from animate movies to animaed series,comics and games.",
  buttonLabel: "View Work",
  imgStart: "start",
  img: "images/afr.png",
  alt: "Vault",
  url: "//play.google.com/store/apps/details?id=com.millsmedia.afrotalesplus&hl=en",
  target: "_blank",
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "DATA ANALYTICS",
  headline: "Every transaction is stored on our secure cloud database",
  description:
    "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
  buttonLabel: "Sign Up Now",
  imgStart: "start",
  img: "images/svg-8.svg",
  alt: "Vault",
};


export const homeObjSix = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "AECINEMAX",
  headline: "Featured Works",
  description: "Your African Cinema Experience",
  buttonLabel: "View Our Work",
  imgStart: "",
  img: "images/aec.png",
  alt: "Aecinemax",
  url: "//aecinemax.com",
  target: "_blank",
};
