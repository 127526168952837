import React from "react";
import HeroSection from "../../HeroSection";
import { homeObjThree,homeObjFive,homeObjSix } from "./Data";

export default function FeaturedWorks() {
  return (
    <>
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjFive} />
      <HeroSection {...homeObjSix} />
      
    </>
  );
}
